.arrowList{
  &Wrapper{
    margin: 17px 0 10px 0;
    padding: 42px 19px 35px;
    background: var(--light-white);
    box-shadow: 0px 8px 15px rgba(52, 105, 179, 0.1);
  }
  &Title{
    text-transform: none;
    text-align: start;
    margin-bottom: 12px;
  }
  &List{
    display: flex;
    width: 100%;
    padding: 22px 34px;
    border-left: 5px solid #3290FF;
    background: var(--light-white);
    box-shadow: 0px 8px 15px rgba(52, 105, 179, 0.1);
    @include breakpoint(small down) {
      padding: 16px 24px;
    }
  }
  img{
    @include breakpoint(small down) {
      display: none;
    }
  }
  ul.arrowListItems{
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    padding: 13px 1px 5px 1px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    @include breakpoint(medium down) {
      display: block;
    }
  }

  li.arrowListItem{
    position: relative;
    margin-bottom: 8px;
    font-size: 15px;
    display: flex;
    &::before{
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      padding-right: 16px;
      background: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.03125' width='20' height='20' rx='10' fill='%231672DF'/%3E%3Cpath d='M14.8349 10.4031L8.389 17.3076C8.27896 17.4254 8.14865 17.4844 7.99807 17.4844C7.84749 17.4844 7.71718 17.4254 7.60714 17.3076L6.16506 15.7629C6.05502 15.645 6 15.5055 6 15.3442C6 15.1829 6.05502 15.0433 6.16506 14.9254L10.778 9.98438L6.16506 5.04331C6.05502 4.92544 6 4.78586 6 4.62457C6 4.46328 6.05502 4.32371 6.16506 4.20584L7.60714 2.66117C7.71718 2.54331 7.84749 2.48438 7.99807 2.48438C8.14865 2.48438 8.27896 2.54331 8.389 2.66117L14.8349 9.56564C14.945 9.68351 15 9.82308 15 9.98438C15 10.1457 14.945 10.2852 14.8349 10.4031Z' fill='white'/%3E%3C/svg%3E") no-repeat center center / contain;
      margin-top: 4px;
      margin-right: 2px;
    }
  }
  &Simple{
    strong{
      color: var(--bright-blue);
    }
    &Items{
      list-style: none;
      padding: 0;
      margin: 0;
    }
    li.arrowListSimpleItem{
      font-size: 16px;
      display: block;
      padding-left: 34px;
      &::before{
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}