.prosCons{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
  background: var(--white);
  border: 1px solid var(--light-blue);
  box-shadow: 0px 8px 15px rgba(52, 105, 179, 0.1);
  border-radius: var(--main-border-radius);
  width: 100%;
  @include breakpoint(medium down) {
    display: block;
  }
  &Title{
    padding:9px;
    text-align: center;
    border-bottom: 1px solid var(--light-blue);
    text-transform: uppercase;
    margin-bottom: 4px;
  }
  &Item{
    padding: 5px 11px;
    display: flex;
    align-items: center;
    @include breakpoint(medium down) {
      align-items: start;
    }
  }
  .proses{
    padding-bottom: 5px;
    &Item{
      &::before{
        content: "";
        display: block;
        min-width: 13px;
        height: 13px;
        background: url("data:image/svg+xml,%3Csvg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.2863 5.8005V7.51479C13.2863 7.75288 13.2029 7.95526 13.0363 8.12193C12.8696 8.2886 12.6672 8.37193 12.4291 8.37193H8.71484V12.0862C8.71484 12.3243 8.63151 12.5267 8.46484 12.6934C8.29818 12.86 8.0958 12.9434 7.8577 12.9434H6.14342C5.90532 12.9434 5.70294 12.86 5.53627 12.6934C5.36961 12.5267 5.28627 12.3243 5.28627 12.0862V8.37193H1.57199C1.33389 8.37193 1.13151 8.2886 0.964844 8.12193C0.798177 7.95526 0.714844 7.75288 0.714844 7.51479V5.8005C0.714844 5.56241 0.798177 5.36003 0.964844 5.19336C1.13151 5.02669 1.33389 4.94336 1.57199 4.94336H5.28627V1.22907C5.28627 0.990978 5.36961 0.788597 5.53627 0.62193C5.70294 0.455263 5.90532 0.37193 6.14342 0.37193H7.8577C8.0958 0.37193 8.29818 0.455263 8.46484 0.62193C8.63151 0.788597 8.71484 0.990978 8.71484 1.22907V4.94336H12.4291C12.6672 4.94336 12.8696 5.02669 13.0363 5.19336C13.2029 5.36003 13.2863 5.56241 13.2863 5.8005Z' fill='%2328A745'/%3E%3C/svg%3E") no-repeat center center / contain;
        margin-right: 10px;
        @include breakpoint(medium down) {
          margin-top: 6px;
        }
      }
    }
  }
  .conses{
    padding-bottom: 5px;
    &Item{
      &::before{
        content: "";
        display: block;
        min-width: 13px;
        height: 4px;
        background: url("data:image/svg+xml,%3Csvg width='14' height='5' viewBox='0 0 14 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.2863 1.79966V3.51395C13.2863 3.75205 13.2029 3.95443 13.0363 4.12109C12.8696 4.28776 12.6672 4.37109 12.4291 4.37109H1.57199C1.33389 4.37109 1.13151 4.28776 0.964844 4.12109C0.798177 3.95443 0.714844 3.75205 0.714844 3.51395V1.79966C0.714844 1.56157 0.798177 1.35919 0.964844 1.19252C1.13151 1.02586 1.33389 0.942522 1.57199 0.942522H12.4291C12.6672 0.942522 12.8696 1.02586 13.0363 1.19252C13.2029 1.35919 13.2863 1.56157 13.2863 1.79966Z' fill='%23DE4657'/%3E%3C/svg%3E") no-repeat center center / contain;
        margin-right: 10px;
        @include breakpoint(medium down) {
          margin-top: 10px;
        }
      }
    }
    .prosConsTitle{
      @include breakpoint(medium down) {
        border-top: 1px solid var(--light-blue);
      }
    }
  }
}