.compareTable{
  max-width: 100%;
  overflow: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: start;
  box-shadow: 0px 8px 14px rgba(52, 105, 179, 0.1);
  border-radius: var(--main-border-radius);
  & strong{
    font-weight: 700;
    color: var(--primary-dark-color);
  }
  strong{
    font-weight: 700;
    color: var(--primary-dark-color);
  }
  table{
    text-align: inherit;
    background: var(--light-blue);
    border: 1px solid var(--light-blue);
    border-radius: var(--main-border-radius);
    overflow: hidden;
    @include breakpoint(small down) {
      min-width: 500px;
    }
    td, th{
      background: var(--white);
      border: 1px solid var(--light-blue);
      padding: 8px 24px;
      text-align: center;
    }
    th{
      color: var(--primary-dark-color);
      font-weight: 700;
      border-top: none;
      &:first-child{
        border-radius: 10px 0 0 0;
      }
      &:last-child{
        border-radius: 0 10px 0  0;
      }
    }
    tr:last-child{
      td:first-child{
        border-radius:  0 0 0 10px;
      }
      td:last-child{
        border-radius: 0 0 10px 0;
      }
    }
  }
}