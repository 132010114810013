.casinoAside{
  grid-area: aside;
  width: 350px;
  min-width: 350px;
  @include breakpoint(medium down) {
    width: 100%;
    min-width: 100%;
  }

  &Info{
    background: var(--white);
    box-shadow: 0px 8px 14px rgba(52, 105, 179, 0.1);
    border-radius: 16px;
    padding: 0 11px 11px;
    margin-bottom: 21px;
    &Item{
      display: flex;
      padding: 0 8px;
      @include breakpoint(small down) {
        padding: 0;
      }
    }
    &Button{
      margin-top: 16px;
      width: 100%;
      @include breakpoint(medium down) {
        width: calc(100% - 24px);
        margin-left: 12px;
      }
      @include breakpoint(small down) {
        width: 100%;
        margin-left: 0;
      }
    }
    &Label{
      padding: 7px 8px;
      border-right: 1px solid var(--light-blue);
      width: 172px;
      min-width: 172px;
      @include breakpoint(medium down) {
        min-width: 242px;
      }
      @include breakpoint(small down) {
        min-width: 172px;
      }
      @media(max-width: 375px){
        min-width: 135px;
        width: 135px;
        font-size: 14px;
        padding: 7px 0;
      }
    }
    &Value{
      padding: 7px 8px;
      @media(max-width: 375px){
        font-size: 14px;
        padding: 7px 5px;
      }
    }
  }

}
.asideTop{
  margin: 0 19px;
  background: #E8F5FF;
  border: 1px solid #E8F5FF;
  border-radius: 0px 0px var(--main-border-radius) var(--main-border-radius);
  color: var(--bright-blue);
  padding: 9px;
  text-align: center;
  margin-bottom: 17px;
  font-weight: 700;
  @include breakpoint(medium down) {
    padding: 7px;
  }
}

.asideTop3{
  background: var(--white);
  box-shadow: 0px 8px 14px rgba(52, 105, 179, 0.1);
  border-radius: 16px;
  padding: 0 11px 11px;
  //&Table{
  //  width: 100%;
  //  tbody{
  //    counter-reset: item;
  //  }
  //  td{
  //    display: flex;
  //    justify-content: center;
  //    align-items: center;
  //  }
  //}
  //&Item{
  //  height: 50px;
  //  display: grid;
  //  grid-template-columns: 41px 65px auto 60px;
  //  background-color: var(--light-white);
  //  border-radius: var(--main-border-radius);
  //  margin-bottom: 16px;
  //  align-items: center;
  //  text-align: center;
  //  border: 1px solid var(--light-blue);
  //  position: relative;
  //  @include breakpoint(medium down) {
  //    grid-template-columns: 41px 130px auto 130px;
  //  }
  //  @include breakpoint(small down) {
  //    grid-template-columns: 41px 46px auto 60px;
  //    height: auto;
  //  }
  //}
  //&Number{
  //  &::after{
  //    content: counter(item);
  //    counter-increment: item;
  //    font-size: 12px;
  //    color: var(--white);
  //    width: 24px;
  //    height: 24px;
  //    border-radius: 50%;
  //    background-color: var(--light-grey);
  //    display: flex;
  //    justify-content: center;
  //    align-items: center;
  //  }
  //}
  //&Bonus{
  //  font-size: 15.5px;
  //  font-weight: 700;
  //  border-right: 1px solid var(--light-blue);
  //  border-left: 1px solid var(--light-blue);
  //  color: var(--bright-blue);
  //  height: 100%;
  //}
  //&Logo{
  //  border: 4px solid var(--dark-grey);
  //  overflow: hidden;
  //  border-radius: var(--main-border-radius);
  //  width: 39px;
  //  height: 39px;
  //  margin-left: 10px;
  //  img{
  //    min-width: 39px;
  //    height: 39px;
  //    object-fit: cover;
  //  }
  //  @include breakpoint(medium down) {
  //    margin: 0 auto;
  //  }
  //}
  //&Button{
  //  .button{
  //    padding: 5px 14px;
  //    border-radius: var(--light-border-radius);
  //    &::after{
  //      content: "";
  //      display: block;
  //      width: 18px;
  //      height: 18px;
  //      background: url("data:image/svg+xml,%3Csvg width='9' height='13' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.94531 6.85156L2.14844 12.6484C2.04948 12.7474 1.93229 12.7969 1.79688 12.7969C1.66146 12.7969 1.54427 12.7474 1.44531 12.6484L0.148438 11.3516C0.0494792 11.2526 0 11.1354 0 11C0 10.8646 0.0494792 10.7474 0.148438 10.6484L4.29688 6.5L0.148438 2.35156C0.0494792 2.2526 0 2.13542 0 2C0 1.86458 0.0494792 1.7474 0.148438 1.64844L1.44531 0.351562C1.54427 0.252604 1.66146 0.203125 1.79688 0.203125C1.93229 0.203125 2.04948 0.252604 2.14844 0.351562L7.94531 6.14844C8.04427 6.2474 8.09375 6.36458 8.09375 6.5C8.09375 6.63542 8.04427 6.7526 7.94531 6.85156Z' fill='white'/%3E%3C/svg%3E") no-repeat center center / contain;
  //    }
  //  }
  //
  //}
  .topElement{
    position: absolute;
    background-image: url('./../images/after_table_top.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 173px;
    color: var(--white);
    width: 173px;
    top: -14px;
    left: 1px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21px;
  }
}

.asidePopular{
  background: var(--white);
  box-shadow: 0px 8px 14px rgba(52, 105, 179, 0.1);
  border-radius: 16px;
  padding: 0 11px 11px;
  margin-top: 20px;
  &Items{
    list-style: none;
    padding: 0;
    margin: 0;
 }
  &Item{
    padding: 5px 0;
    &:not(:last-child){
      border-bottom: 1px solid var(--light-blue);
    }
  }
}


.asideSlot{
  background: var(--white);
  border: 1px solid var(--light-blue);
  border-radius: var(--main-border-radius);
  padding: 11px;
  margin-bottom: 20px;
  &Wrapper{
    max-width: 350px;
    margin: 0 auto;
  }
  &Name{
    text-transform: uppercase;
    font-size: 18px;
    padding-bottom: 12px;
    text-align: center;
    border-bottom: 1px solid var(--light-blue);
  }
  &Logo{
    padding: 11px 0 9px 0;
    text-align: center;
  }
  &Link{
    color: #757575;
    text-decoration: underline;
    font-size: 12.5px;
    padding-bottom: 11px;
    display: block;
    text-align: center;
  }
  &Info{
    padding: 11px 0;
    border-bottom: 1px solid var(--light-blue);
    border-top: 1px solid var(--light-blue);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    gap: 5px;
    &Item{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 50px;
      &:not(:last-child){
        border-right: 1px solid var(--light-blue);
      }
    }
  }
  &Rating{
    color: var(--bright-blue);
    font-weight: 700;
    //border-radius: var(--light-border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    //height: 30px;
    //border: 3px solid var(--bright-blue);
    margin: 11px auto 16px auto;
    & > a {
      display: flex;
    }
  }
  &Type{
    padding: 11px 15px;
    border-bottom: 1px solid var(--light-blue);
    border-top: 1px solid var(--light-blue);
    display: grid;
    grid-template-columns: 60px auto;
    gap: 20px;
    &Label{
      text-align: center;
      font-weight: 700;
    }
    &Value{
      a{
        text-decoration: underline;
        font-size: 14px;
        color: var(--dark-blue);
      }
    }
  }
  &Button{
    padding-top: 11px;
    text-align: center;
    .button{
      width: 100%;
      font-size: 18px;
    }
  }
}