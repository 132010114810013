.cookie{
  background-color: #171A29;
  padding: 12px;
  color: var(--white);
  line-height: 24px;
  position: sticky;
  bottom: 0;
  width: 100%;
  display: none;
  z-index: 10;
  &.show{
    display: block;
  }
  &Text{
    flex: 1;
    @include breakpoint(small down) {
      text-align: center;
      margin-bottom: 10px;
    }
  }
  &Wrapper{
    display: flex;
    align-items: center;
    @include breakpoint(small down) {
      flex-direction: column;
    }
  }
  &Buttons{
    display: flex;
    align-items: center;
    gap: 30px;
    @include breakpoint(small down) {
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
    }
  }
  a{
    color: var(--white);
    text-decoration: underline;
  }
  .button{
    height: 36px;
    width: 160px;
  }
}