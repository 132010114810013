.casinoContent{
  padding-top: 10px;
  h2:not([class]){
    text-align: start;
  }
&Wrapper{
  display: grid;
  grid-template-areas:  "main aside"
                        "faq faq";
  gap: 24px;
  @include breakpoint(medium down) {
    grid-template-areas:  "main main"
                        "faq faq"
                        "aside aside";
  }
}
  &Main{
    grid-area: main;
  }
}