.afterTable{
  position: sticky;
  height: 88px;
  bottom: 0;
  width: 100%;
  padding: 0 52px;
  background: linear-gradient(94.87deg, rgba(4, 74, 157, 0.72) -15.23%, #080E14 94.19%);
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  //background-image: url('./../assets/img/after_table_bg.png');
  background-repeat: no-repeat;
  background-size: 110% 200px;
  background-position: center;
  margin: 2px 0;
  color: var(--white);
  display: none;
  .container{
    height: 100%;
  }
  &Wrapper{
    position: relative;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 8px 0;
    min-width: 100%;
    height: 100%;
  }
  &.showed{
    display: block;
  }
  @include breakpoint(large down) {
    padding: 8px 19px;
  }
  @include breakpoint(small down) {
    justify-content: space-between;
    height: 110px;
    background-size: 110% 270px;
    padding: 8px;
  }
  &Top{
    position: absolute;
    //background-image: url('./src/assets/img/after_table_top.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 173px;
    color: var(--white);
    width: 173px;
    top: -6px;
    left: 0;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21px;
    @include breakpoint(large down) {
      top: -11px;
    }
  }
  &Logo{
    border: 3px solid var(--medium-blue);
    border-radius: var(--main-border-radius);
    overflow: hidden;
    width: 68px;
    height: 68px;
    img{
      height: 100%;
      object-fit: cover;
    }
    @include breakpoint(small down) {
      min-width: 56px;
      height: 56px;
    }
  }
  &Name{
    font-size: 26px;
    font-weight: 700;
    margin-left: 53px;
    margin-right: auto;
    @include breakpoint(large down) {
      margin-left: 23px;
    }
    @include breakpoint(small down) {
      display: none;
    }
  }
  &Bonus{
    font-weight: 700;
    text-align: center;
    &Label{
      font-size: 18px;
      @include breakpoint(small down) {
        font-size: 12px;
      }
    }
    &Value{
      color: var(--bright-blue);
      font-size: 22px;
      @include breakpoint(small down) {
        font-size: 16px;
      }
    }
  }
  &Link{
    margin-left: auto;
    margin-right: 23px;
    & > button {
      width: 200px;
      height: 36px;
      font-size: 18px;
    }

    @include breakpoint(large down) {
      margin-right: 23px;
    }
    @include breakpoint(small down) {
      & > button {
        font-size: 16px;
        width: 106px;
      }
      margin-left: 2px;
      margin-right: 0;
    }
  }
  &Close{
    cursor: pointer;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.43905 7.95598L15.919 1.47596C16.0646 1.30592 16.1408 1.08718 16.1322 0.863467C16.1235 0.639758 16.0307 0.427545 15.8725 0.269239C15.7142 0.110933 15.5019 0.0181924 15.2783 0.00955045C15.0545 0.000909675 14.8357 0.077004 14.6657 0.222628L8.18572 6.70264L1.70574 0.213739C1.53569 0.0681154 1.31696 -0.00797892 1.09325 0.000663055C0.869535 0.00930383 0.657323 0.102043 0.499016 0.26035C0.340711 0.418656 0.247972 0.630868 0.23933 0.854583C0.230689 1.07829 0.306783 1.29703 0.452406 1.46708L6.93239 7.95598L0.443518 14.436C0.350467 14.5157 0.274893 14.6137 0.22154 14.724C0.168186 14.8342 0.138203 14.9545 0.133475 15.0768C0.128747 15.1993 0.149373 15.3213 0.194062 15.4354C0.238751 15.5495 0.306535 15.6531 0.393162 15.7396C0.479788 15.8263 0.583386 15.8941 0.697452 15.9387C0.811519 15.9835 0.933592 16.0041 1.05601 15.9993C1.17842 15.9947 1.29854 15.9646 1.40883 15.9113C1.51911 15.8579 1.61716 15.7824 1.69684 15.6893L8.18572 9.2093L14.6657 15.6893C14.8357 15.8349 15.0545 15.9111 15.2783 15.9023C15.5019 15.8937 15.7142 15.8011 15.8725 15.6427C16.0307 15.4844 16.1235 15.2722 16.1322 15.0484C16.1408 14.8248 16.0646 14.606 15.919 14.436L9.43905 7.95598Z' fill='white'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 16px;
    border: none;
    @include breakpoint(small down) {
      position: absolute;
      width: 43px;
      height: 43px;
      border-radius: 50%;
      top: -18px;
      right: 5px;
      background-color: #062D5A;
      background-size: 14px 14px;
    }
  }
  &Present{
    display: none;
    position: fixed;
    right: 0;
    top: 50px;
    width: 80px;
    z-index: 100;
    &.showed{
      display: block;
    }
    button{
      border: 1px solid var(--primary-color);
      background-color: var(--white);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      cursor: pointer;
    }
  }

}