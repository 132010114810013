.orderList{
  counter-reset: item;
  &Item{
    margin-bottom: 16px;
  }
  &Title{
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &::before{
      content: counter(item);
      counter-increment: item;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: var(--bright-blue);
      color: var(--white);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 6px;
      font-size: 14px;
    }
  }
  &.orderListSimple{
      padding-left: 20px;
    &.no-padding{
      padding-left: 0;
    }
    .orderListTitle{
      display: block;
      &::before{
        float: left;
      }
    }
  }
}