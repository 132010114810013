.bestBonusesTable{
  overflow: auto;
  border-radius: var(--main-border-radius);
  margin-bottom: 18px;
  &Table{
    border-radius: var(--main-border-radius);
    box-shadow: 0px 8px 14px rgba(52, 105, 179, 0.1);
    overflow: hidden;
    width: 100%;
    background: var(--light-blue);
    thead{
      background: var(--white);
    }
    th{
      background: var(--light-blue);
      font-weight: 400;
      padding: 7px 24px;
      text-align: start;
      @include breakpoint(small down) {
        padding: 7px 20px;
      }
      &:not(:last-child){
        border-right: 2px solid var(--white);
      }
    }
    td{
      padding: 7px 24px;
      background-color: var(--white);
      border: 1px solid var(--light-blue);
      @include breakpoint(small down) {
        padding: 7px 20px;
      }
    }
    tbody{
      tr:last-child{
        td:first-child{
          border-radius: 0 0 0 10px;
        }
        td:last-child{
          border-radius: 0 0 10px 0;
        }
      }
    }
  }
}