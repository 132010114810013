.slotInfoTable{
margin: 10px 0;
  overflow: auto;
table{
  text-align: inherit;
  background: var(--light-blue);
  border: 1px solid var(--light-blue);
  border-radius: var(--main-border-radius);
  box-shadow: 0px 8px 14px rgba(52, 105, 179, 0.1);
  overflow: hidden;
  @include breakpoint(small down) {
    min-width: 500px;
  }
  td, th{
    background: var(--white);
    border: 1px solid var(--light-blue);
    padding: 8px 24px;
    text-align: center;
    min-width: 192px;
  }
  th{
    font-weight: 700;
    border-top: none;
    background: rgba(22, 114, 223, 0.2);
    &:first-child{
      border-radius: 10px 0 0 0;
    }
    &:last-child{
      border-radius: 0 10px 0  0;
    }
  }
  tr:last-child{
    td:first-child{
      border-radius:  0 0 0 10px;
    }
    td:last-child{
      border-radius: 0 0 10px 0;
    }
  }
}
}