.share{
  margin: 30px auto;
  &Wrapper{
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  &Items{
    display: flex;
    align-items: center;
    gap: 24px;
    list-style: none;
    padding: 0 0 0 13px;
    margin: 0;
  }
  &Item{
    a{
      background-color: var(--medium-grey);
      width: 32px;
      height: 32px;
      border-radius: var(--light-border-radius);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}