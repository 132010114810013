.doubleList{
  ul.doubleListItems{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    list-style: none;
    padding: 0;
    margin: 0;
    @include breakpoint(small down) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  li.doubleListItem{
    position: relative;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    @include breakpoint(small down) {
      padding-left: 0px;
      padding-bottom: 10px;
    }
    &::before{
      content: "";
      width: 20px;
      height: 20px;
      background-color: #60A9FF;
      border-radius: 50%;
      display: block;
      margin-right: 6px;
      min-width: 20px;
    }
  }
}