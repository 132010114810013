.quote{
padding: 16px 21px;
  border-left: 4px solid var(--bright-blue);
  background: var(--light-white);
  box-shadow: 0px 8px 15px rgba(52, 105, 179, 0.1);
  margin-bottom: 15px;
  strong{
    color: var(--bright-blue);
    font-weight: 700;
  }
}