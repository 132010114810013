.author{
  &Wrapper{
    background: var(--white);
    border: 1px solid var(--dark-blue);
    border-radius: var(--main-border-radius);
    overflow: hidden;
  }
  &Logo{
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &Name{
    a{
      font-size: 20px;
      color: var(--white);
      font-weight: 700;
    }
  }
  &Position{
    padding-top: 1px;
    font-size: 12px;
    color: var(--secondary-color);
    @include breakpoint(small down) {
      margin-bottom: 12px;
    }
  }
  &Top{
    display: flex;
    background-color: var(--dark-blue);
    padding: 8px 12px;
    color: var(--white);
    @include breakpoint(small down) {
      padding: 12px;
    }
  }
  &Box{
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    margin:0 30px 0 9px;
    @include breakpoint(small down) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-right: 0;
    }
  }
  &ShortText{
    padding: 5px 0;
  }
  &Text{
    padding: 24px 16px;
  }
  &Socials{
    display: flex;
    flex-wrap: wrap;
    &Item{
      background-color: var(--medium-grey);
      width: 32px;
      height: 32px;
      border-radius: var(--light-border-radius);
      display: flex;
      justify-content: center;
      align-items: center;
      &:not(:last-child) {
        margin-right: 24px;
      }
      padding: 4px;
      @include breakpoint(small down) {
        margin-bottom: 2px;
      }
    }
  }
}