.tableImg{
  &Img{
    width: 100%;
    height: auto;
    object-fit: contain;
    margin-bottom: 25px;
  }
  table{
    width: 100%;
  }
  tbody{
    counter-reset: item;
  }
  thead{
    tr{
      height: 32px;
      @include breakpoint(small down) {
        min-height: 32px;
      }
      th{
        font-weight: 400;
        border-right: 1px solid var(--light-blue);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      th:nth-child(6), th:last-child{
        border: none;
      }
      th:nth-child(6), th:last-child{
        @include breakpoint(large down) {
          display: none;
        }
      }
      th:nth-child(5){
        @include breakpoint(small down) {
          display: none;
        }
      }
    }

  }
  td{
    border-right: 1px solid var(--light-blue);
    font-weight: 700;
  }
  td:nth-child(1), td:last-child{
    border: none;
  }
  &Item{
    filter: drop-shadow(0px 8px 14px rgba(52, 105, 179, 0.1));
    border-radius: var(--main-border-radius);
    height: 70px;
    display: grid;
    grid-template-columns: 47px 105px 360px 180px auto 105px 165px;
    margin-bottom: 10px;
    background-color: var(--white);
    align-items: center;
    position: relative;
    @include breakpoint(large down) {
      grid-template-columns: 45px 75px auto 160px 250px;
    }
    @include breakpoint(medium down) {
      grid-template-columns: 45px 75px auto 150px 200px;
    }
    @include breakpoint(small down) {
      height: auto;
      min-height: 50px;
      grid-template-columns: 35px 60px auto 70px;
    }
  }
  td.tableImgLogo{
    background-color: var(--dark-grey);
    padding: 0;
    width: 59px;
    height: 59px;
    margin: 0 auto;
    border: 4px solid var(--dark-grey);
    border-right: 4px solid var(--dark-grey);
    border-radius: var(--main-border-radius);
    img{
      width: 51px;
      height: 51px;
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
    }
    @include breakpoint(large down) {
      margin: 0 10px;
    }
    @include breakpoint(small down) {
      width: 39px;
      height: 39px;
    }
  }
  &Spellen{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22.5px;
    @include breakpoint(large down) {
      display: none;
    }

  }
  &Number{
    position: relative;
    height: 100%;
    &::after{
      position: absolute;
      content: counter(item);
      counter-increment: item;
      top: 50%;
      transform: translateY(-50%);
      left: 6px;
      font-size: 18px;
      color: var(--white);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: var(--light-grey);
      display: flex;
      justify-content: center;
      align-items: center;
      @include breakpoint(small down) {
        width: 24px;
        height: 24px;
        font-size: 12px;
      }
    }
  }
  &Name{
    border-right: 1px solid var(--light-blue);
    border-left: 1px solid var(--light-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 0 3px;
    font-size: 22.5px;
    @include breakpoint(medium down) {
      font-size: 14.5px;
    }
    @include breakpoint(small down) {
      display: none;
    }
  }
  &Bonus{
    border-right: 1px solid var(--light-blue);
    border-left: 1px solid var(--light-blue);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--bright-blue);
    padding: 0 3px;
    text-align: center;
    font-size: 17.5px;
    @include breakpoint(medium down) {
      font-size: 15.5px;
    }
    @include breakpoint(small down) {
      font-size: 15px;
      border-left: 1px solid var(--light-blue);
    }
  }
  &Button{
    text-align: center;
    .button{
      font-size: 17px;
    }
    @include breakpoint(small down) {
      display: none;
    }
  }
  &ButtonMobile{
    display: none;
    justify-content: center;
    flex-direction: column;
    padding: 5px 0;
    @include breakpoint(small down) {
      display: flex;
      align-items: center;
      gap: 8px;
      .read{
        color: var(--dark-blue);
        font-size: 13px;
        text-decoration: underline;
        text-decoration-style: dotted;
      }
      .button{
        max-width: 48px;
        padding: 5px 15px;
        &::after{
          content: "";
          display: block;
          width: 18px;
          height: 18px;
          background: url("data:image/svg+xml,%3Csvg width='9' height='13' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.94531 6.85156L2.14844 12.6484C2.04948 12.7474 1.93229 12.7969 1.79688 12.7969C1.66146 12.7969 1.54427 12.7474 1.44531 12.6484L0.148438 11.3516C0.0494792 11.2526 0 11.1354 0 11C0 10.8646 0.0494792 10.7474 0.148438 10.6484L4.29688 6.5L0.148438 2.35156C0.0494792 2.2526 0 2.13542 0 2C0 1.86458 0.0494792 1.7474 0.148438 1.64844L1.44531 0.351562C1.54427 0.252604 1.66146 0.203125 1.79688 0.203125C1.93229 0.203125 2.04948 0.252604 2.14844 0.351562L7.94531 6.14844C8.04427 6.2474 8.09375 6.36458 8.09375 6.5C8.09375 6.63542 8.04427 6.7526 7.94531 6.85156Z' fill='white'/%3E%3C/svg%3E") no-repeat center center / contain;
        }
      }

    }
  }
  .topElement{
    position: absolute;
    background-image: url('./../images/after_table_top.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 173px;
    color: var(--white);
    width: 173px;
    top: -9px;
    left: 12px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21px;
    @include breakpoint(small down) {
      left: 2px;
    }
  }
  .tableButton{
    margin-top: 20px;
  }
}