.reveal {
  max-width: 786px;
  width: calc(100% - 40px);
  max-height: 100%;
  padding: 32px 22px 18px 22px;
  background: var(--light-blue);
  border-radius: var(--main-border-radius);
  border: 1px solid var(--bright-blue);
  position: relative;

  &Wrap {
    background: rgba(#000, 0.7);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;

    &:not(.is-open) {
      display: none;
    }
  }

  &Title {
    max-width: 430px;
    margin: 0 auto 8px auto;
    text-align: center;
    font-size: 18px;
  }

  &Wrapper {
    max-height: calc(100vh - 246px);
    overflow-y: auto;
    @include breakpoint(small down) {
      max-height: calc(100vh - 260px);
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #d6e9ff;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--bright-blue);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--bright-blue);
    }
  }

  &Items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    margin: 16px auto;
    max-width: 724px;
    @include breakpoint(small down) {
      grid-template-columns: repeat(1, 238px);
      justify-content: center;
    }
  }

  &Item {
    background: var(--white);
    box-shadow: 0px 8px 14px rgba(52, 105, 179, 0.1);
    border-radius: var(--main-border-radius);
    padding: 6px 9px 16px 9px;
    max-width: 240px;
    text-align: center;
    @include breakpoint(small down) {
      margin-right: 4px;
    }
  }

  &Logo {
    height: 150px;
    width: 100%;

    img {
      border-radius: var(--main-border-radius);
      width: 100%;
      object-fit: cover;
    }
  }

  &Info {
    margin: 8px 0;
    border-bottom: 1px solid var(--light-blue);
  }

  &Box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4px;
    margin-bottom: 8px;

    img {
      border-radius: var(--light-border-radius);
    }
  }

  &Label {
    font-size: 15px;
  }

  &Value {
    font-size: 25px;
    font-weight: 700;
  }

  &Link {
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 16px;

    &::after {
      margin-top: 5px;
      margin-left: 4px;
      content: '';
      display: block;
      width: 5px;
      height: 8px;
      background: url("data:image/svg+xml,%3Csvg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.9083 4.22333L1.32722 7.90571C1.26609 7.96857 1.19369 8 1.11004 8C1.02638 8 0.95399 7.96857 0.892857 7.90571L0.0916988 7.08189C0.0305663 7.01902 0 6.94458 0 6.85856C0 6.77254 0.0305663 6.6981 0.0916988 6.63524L2.65444 4L0.0916988 1.36476C0.0305663 1.3019 0 1.22746 0 1.14144C0 1.05542 0.0305663 0.980976 0.0916988 0.918114L0.892857 0.0942926C0.95399 0.0314312 1.02638 0 1.11004 0C1.19369 0 1.26609 0.0314312 1.32722 0.0942926L4.9083 3.77667C4.96943 3.83954 5 3.91398 5 4C5 4.08602 4.96943 4.16046 4.9083 4.22333Z' fill='%23A8FEB6'/%3E%3Cpath d='M4.9083 4.22333L1.32722 7.90571C1.26609 7.96857 1.19369 8 1.11004 8C1.02638 8 0.95399 7.96857 0.892857 7.90571L0.0916988 7.08189C0.0305663 7.01902 0 6.94458 0 6.85856C0 6.77254 0.0305663 6.6981 0.0916988 6.63524L2.65444 4L0.0916988 1.36476C0.0305663 1.3019 0 1.22746 0 1.14144C0 1.05542 0.0305663 0.980976 0.0916988 0.918114L0.892857 0.0942926C0.95399 0.0314312 1.02638 0 1.11004 0C1.19369 0 1.26609 0.0314312 1.32722 0.0942926L4.9083 3.77667C4.96943 3.83954 5 3.91398 5 4C5 4.08602 4.96943 4.16046 4.9083 4.22333Z' fill='%231672DF'/%3E%3C/svg%3E")
        no-repeat center center / contain;
    }

    @include breakpoint(small down) {
      justify-content: center;
    }
  }

  &Close {
    position: absolute;
    right: -12px;
    top: -12px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: var(--bright-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='13.182' y='3.18198' width='5' height='14' rx='1' transform='rotate(45 13.182 3.18198)' fill='white'/%3E%3Crect x='16.7175' y='13.0815' width='5' height='14' rx='1' transform='rotate(135 16.7175 13.0815)' fill='white'/%3E%3C/svg%3E")
        no-repeat center center / contain;
    }
  }

  &.hide {
    display: none;
  }
}

.video-reveal {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 9999;
  background: rgba(#000, 0.8);
  backdrop-filter: blur(10px);
  padding: 20px;

  &-cont {
    margin: auto;
    width: 100%;
    max-width: 150vh;
    height: auto;
    position: relative;
  }

  &-close {
    border: 0;
  }

  iframe {
    width: 100%;
    max-width: 100%;
    aspect-ratio: 16/9;
    max-height: calc(100% - 40px);
  }
}
