.compareCasinosTable{
  margin: 16px auto;
  overflow-y: auto;
  padding-bottom: 5px;
  table{
    background: var(--white);
    box-shadow: 0px 8px 15px rgba(52, 105, 179, 0.1);
    width: 100%;
    border-radius: var(--main-border-radius);
  }
  th{
    color: var(--bright-blue);
    font-weight: 700;
  }
  td, th{
    border: 8px solid var(--white);
    text-align: center;
    background: var(--light-white);
    padding: 12px 32px;
    min-width: 300px;
    @include breakpoint(small down) {
      padding: 8px 16px;
      border-width: 4px;
    }
  }
}