.howTo {
  padding-top: 20px;
  counter-reset: item;
  counter-set: item;

  &Grid {
    display: flex;
    overflow: auto;
    padding: 20px;
    margin: 0 -20px 20px;
    gap: 12px;
  }

  &Item {
    background: var(--white);
    box-shadow: 0 8px 14px rgba(52, 105, 179, 0.1);
    border-radius: 16px;
    overflow: hidden;
    flex: 1 0 240px;

    @include breakpoint(xlarge) {
      flex: 1 0 190px;
    }

    &Title {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 10px;
      background: var(--medium-white);
      font-weight: 700;
      line-height: 21px;
      text-transform: uppercase;
      position: relative;
      padding: 4px 10px;

      :last-child {
        align-self: center;
      }

      &:before {
        content: counter(item);
        counter-increment: item;
        top: 4px;
        left: 0;
        margin-left: 6px;
        font-size: 18px;
        font-weight: 400;
        color: var(--white);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: var(--light-grey);
        display: flex;
        justify-content: center;
        align-items: center;
        @include breakpoint(small down) {
          top: 10px;
        }
      }

      @include breakpoint(small down) {
        padding: 12px 10px 19px 50px;
      }
    }

    &Img {
      width: 182px;
      height: 182px;
      border-radius: 50%;
      background-color: var(--medium-white);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px auto 0 auto;
      @include breakpoint(small down) {
        margin: 10px auto 0 auto;
        width: 126px;
        height: 126px;
      }

      img {
        width: 119px;
        height: 119px;
        @include breakpoint(small down) {
          width: 70px;
          height: 70px;
        }
      }
    }

    &Text {
      font-size: 14px;
      padding: 15px;
      @include breakpoint(small down) {
        padding: 12px 12px 24px 12px;
      }
    }
  }

  &Info {
    width: 475px;
    height: 44px;
    background-color: var(--secondary-color);
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    color: var(--white);
    margin: 0 auto 25px;

    &Item {
      display: flex;
      gap: 4px;
    }

    @include breakpoint(small down) {
      flex-direction: column;
      border-radius: var(--main-border-radius);
      height: auto;
      padding: 10px;
      gap: 3px;
      width: 100%;
      margin-top: 10px;
    }
  }
}
