.bonusCards{
  &Wrapper{
    background: var(--light-white);
    box-shadow: 0px 8px 15px rgba(52, 105, 179, 0.1);
    padding: 4px 4px 7px 4px;
    overflow: auto;
  }
  &Items{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0;
  }
  &Item{
    border: 8px solid var(--light-blue);
    text-align: center;
    min-width: 115px;
    &Text{
      padding: 11px;
      color: var(--bright-blue);
      border-top: 8px solid var(--light-blue);
    }
    &Img{
      padding: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        max-width: 100%;
        object-fit: contain;
      }
    }
  }
}