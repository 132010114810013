.checkList{
  &Wrapper{
    margin: 17px 0 10px 0;
  }
  &Title{
    text-transform: none;
    text-align: start;
    margin-bottom: 12px;
  }
  &List{
    display: flex;
    width: 100%;
    padding: 22px 34px;
    border-left: 5px solid #3290FF;
    background: var(--light-white);
    box-shadow: 0px 8px 15px rgba(52, 105, 179, 0.1);
    @include breakpoint(small down) {
      padding: 16px 24px;
    }
  }
  img{
    @include breakpoint(small down) {
      display: none;
    }
  }
  ul.checkListItems{
    width: 100%;
    list-style-position: inside;
    margin: 0;
    padding: 0;
    padding: 13px 1px 5px 1px;
    @include breakpoint(small down) {
      padding: 0;
    }
  }

  li.checkListItem{
    position: relative;
    margin-bottom: 8px;
    font-size: 15px;
    strong{
      color: var(--bright-blue);
    }
    &::marker{
        content: '✔  ';
     }
  }
}

.checkListSimple{
ul{
  padding: 0 0 0 15px;
  margin: 0;
  list-style-position: inside;
}
  li{
    margin-bottom: 8px;
    font-size: 15px;
    strong{
      color: var(--bright-blue);
    }
    &::marker{
      content: '✔  ';
    }
  }
}