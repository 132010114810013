.blackList{

  &Inner{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    @include breakpoint(large down) {
      gap: 20px;
    }
    @include breakpoint(small down) {
      grid-template-columns: repeat(1, 100%);
    }
  }

  &Item{
    background-color: var(--white);
    box-shadow: 0px 8px 14px rgb(156 72 72 / 24%);
    border-radius: 16px;
    padding: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    line-height: 25px;
    min-height: 350px;
    border: 1px solid #F9C0C3;
  }

  &Logo{
    background-color: var(--dark-grey);
    width: 158px;
    margin: 0 auto;
    border: 4px solid var(--dark-grey);
    border-radius: var(--main-border-radius);
    overflow: hidden;
    height: 138px;
    margin-top: 5px;

    @include breakpoint(medium down) {
      width: 139px;
      height: 126px;
    }

    @include breakpoint(small down) {
      width: 158px;
      height: 138px;
    }

    img{
      max-width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  &Minuses{
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0;
    list-style: none;

    li{
      display: flex;
      gap: 5px;
      margin-bottom: 5px;
      align-items: center;
      text-align: start;

      &:before {
        background-color: #FF5353;
        content: "";
        display: block;
        min-width: 18px;
        height: 18px;
        border-radius: 50%;
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.3225 10.2673V12.9459C22.3225 13.3179 22.1923 13.6341 21.9319 13.8945C21.6715 14.1549 21.3553 14.2852 20.9833 14.2852H4.01897C3.64695 14.2852 3.33073 14.1549 3.07031 13.8945C2.8099 13.6341 2.67969 13.3179 2.67969 12.9459V10.2673C2.67969 9.89528 2.8099 9.57906 3.07031 9.31864C3.33073 9.05822 3.64695 8.92801 4.01897 8.92801H20.9833C21.3553 8.92801 21.6715 9.05822 21.9319 9.31864C22.1923 9.57906 22.3225 9.89528 22.3225 10.2673Z' fill='white'/%3E%3C/svg%3E");
        background-position: center;
        padding: 2px;
      }
    }
  }

  &Name{
    font-weight: 700;
    font-size: 25px;
    border-bottom: 1px solid var(--light-blue);
    display: block;
    text-align: center;
    margin: 20px auto;
    width: 100%;
    padding-bottom: 20px;
  }
}