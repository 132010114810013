.tags{
  padding-bottom: 15px;
  &Wrapper{
    background: var(--dark-blue);
    border: 1px solid var(--secondary-color);
    border-radius: var(--main-border-radius);
    padding: 15px 62px;
    @include breakpoint(medium down) {
      padding: 20px 57px;
    }
    @include breakpoint(small down) {
      padding: 16px 13px;
    }

  }
  &Items{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 22px;
    @include breakpoint(small down) {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-column-gap: 4px;
      grid-row-gap: 10px;
    }
  }
  &Item{
    color: var(--white);
    font-size: 18px;
    &::before{
      content: '✔';
      color: #A8FEB6;
      opacity: 0.3;
      margin-right: 4px;
    }
    a{
      color: inherit;
    }

  }

}