.circleList{
  &Wrapper{
    background: #F9FCFF;
    box-shadow: 0px 8px 15px rgba(52, 105, 179, 0.1);
    padding: 42px 19px 20px;
    @include breakpoint(medium down) {
      padding: 22px 12px 6px 12px;
    }
  }

ul.circleListItems{
list-style: none;
  padding: 0 0 17px 15px;
  margin: 0;
  @include breakpoint(medium down) {
    padding: 0 0 17px 0;
  }
}
  li.circleListItem{
    &:not(:last-child){
      margin-bottom: 8px;
      @include breakpoint(medium down) {
        margin-bottom: 24px;
      }
    }
    strong{
      font-weight: 700;
      color: var(--bright-blue);
      position: relative;
      padding-left: 30px;
      &:before{
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        background-color: var(--bright-blue);
        border-radius: 50%;
        top: 0;
        left: 0;
      }
    }
  }
}