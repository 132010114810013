//@import "../_variables.scss";

.footer{
  padding: 26px 0 12px;
  background-color: var(--dark-blue);
  color: #FBFBFB;
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  a{
    font-weight: 400;
    color: #FBFBFB;
  }
  &Content{
    display: grid;
    grid-template-columns: 270px auto 212px;
    grid-gap: 35px;
    padding-bottom: 20px;
    @include breakpoint(large down) {
      display: block;
    }
    @include breakpoint(small down) {
      padding-bottom: 32px;
    }
  }
  &Logo{
    img{
      width: 120px;
      height: 41px;
    }
    @include breakpoint(large down) {
      display: none;
      &.mobile{
        display: block;
      }
    }
  }
  &Description{
    color: var(--secondary-color);
    font-size: 12px;
  }
  &Mobile{
    display: none;
    @include breakpoint(large down) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &Menus{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 14px;
    @include breakpoint(large down) {
      padding-top: 24px;
      padding-bottom: 32px;
    }
    @include breakpoint(small down) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 25px;
      padding-bottom: 44px;
    }
    &Title{
      text-transform: uppercase;
      margin-bottom: 18px;
      font-weight: 400;
    }
    &List{
      &Item{

      }
    }
  }
  &Socials{
    margin-bottom: 20px;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    gap: 28px;
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
      background-color: var(--medium-grey);
      border-radius: var(--light-border-radius);
    }
    @include breakpoint(small down) {
      gap: 23px;
    }
    @include breakpoint(large down) {
      display: none;
      &.mobile{
        display: flex;
      }
    }
  }
  &Partners{
    @include breakpoint(large down) {
      display: flex;
      flex-wrap: wrap;
      max-width: 422px;
      margin: 0 auto;
      justify-content: space-between;
    }
    @include breakpoint(small down) {
      max-width: 212px;
    }
  }
  &Bottom{
    padding-top: 10px;
    border-top: 1px solid var(--secondary-color);
    ul.footerBottomMenuList{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      @include breakpoint(large down) {
        max-width: 620px;
        margin: 0 auto;
      }
    }
    & li{
      text-transform: uppercase;
      &:not(:last-child){
        margin-right: 24px;
        @include breakpoint(small down) {
          margin-right: 16px;
        }
      }
      @include breakpoint(large down) {
        margin-bottom: 24px;
      }
      @include breakpoint(small down) {
        font-size: 14px;
        margin-bottom: 12px;
      }
    }
  }
  #goTop{
    position: fixed;
    bottom: 60px;
    right: 87px;
    z-index: 11;
    background-color: var(--bright-blue);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: 0px 0px 11px 4px rgba(22, 114, 223, 0.26);
    display: none;
    &.active{
      display: flex;
    }
    &::after{
      content: "";
      display: block;
      width: 27px;
      height: 16px;
      background: url("data:image/svg+xml,%3Csvg width='28' height='17' viewBox='0 0 28 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.6053 0.304434L27.3399 12.1933C27.5573 12.3963 27.666 12.6366 27.666 12.9144C27.666 13.1921 27.5573 13.4324 27.3399 13.6354L24.4909 16.2952C24.2736 16.4981 24.0161 16.5996 23.7186 16.5996C23.4211 16.5996 23.1637 16.4981 22.9463 16.2952L13.833 7.78708L4.7197 16.2952C4.50231 16.4981 4.24487 16.5996 3.94738 16.5996C3.6499 16.5996 3.39246 16.4981 3.17507 16.2952L0.326088 13.6354C0.108696 13.4324 0 13.1921 0 12.9144C0 12.6366 0.108696 12.3963 0.326088 12.1933L13.0607 0.304434C13.2781 0.101479 13.5355 0 13.833 0C14.1305 0 14.3879 0.101479 14.6053 0.304434Z' fill='white'/%3E%3C/svg%3E") no-repeat center center / contain;
    }
    @include breakpoint(small down) {
      bottom: 75px;
      right: 65px;
    }
  }
}