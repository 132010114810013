.plusMinus{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  padding: 22px 0;
  @include breakpoint(medium down) {
    grid-template-columns: 100%;
    gap: 22px;
  }
  &Title{
  height: 47px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    border-radius: var(--main-border-radius);
    margin-bottom: 8px;
    padding: 11px;
    &:before{
      content: "";
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-right: 10px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 23px 23px;
      display: block;
    }
  }
  &Item{
    background: var(--white);
    border: 1px solid var(--light-blue);
    box-shadow: 0px 8px 10px rgba(52, 105, 179, 0.14);
    border-radius: var(--main-border-radius);
    margin-bottom: 8px;
    position: relative;
    padding:16px 16px 16px 41px;
    &::before{
      position: absolute;
      content: "";
      display: block;
      width: 16px;
      height: 16px;
    }
  }
  .pluses{
    & strong{
      color: #33BE53;
      font-weight: 700;
    }
    &Title{
      background: rgba(51, 190, 83, 0.06);
      border: 1px solid #33BE53;
      box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.08);
      &:before{
        background-color: #33BE53;
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.3225 9.26702V11.9456C20.3225 12.3176 20.1923 12.6338 19.9319 12.8943C19.6715 13.1547 19.3553 13.2849 18.9833 13.2849H13.1797V19.0884C13.1797 19.4605 13.0495 19.7767 12.7891 20.0371C12.5286 20.2975 12.2124 20.4277 11.8404 20.4277H9.16183C8.78981 20.4277 8.47359 20.2975 8.21317 20.0371C7.95275 19.7767 7.82254 19.4605 7.82254 19.0884V13.2849H2.01897C1.64695 13.2849 1.33073 13.1547 1.07031 12.8943C0.809896 12.6338 0.679688 12.3176 0.679688 11.9456V9.26702C0.679688 8.895 0.809896 8.57878 1.07031 8.31836C1.33073 8.05794 1.64695 7.92773 2.01897 7.92773H7.82254V2.12416C7.82254 1.75214 7.95275 1.43592 8.21317 1.1755C8.47359 0.915085 8.78981 0.784878 9.16183 0.784878H11.8404C12.2124 0.784878 12.5286 0.915085 12.7891 1.1755C13.0495 1.43592 13.1797 1.75214 13.1797 2.12416V7.92773H18.9833C19.3553 7.92773 19.6715 8.05794 19.9319 8.31836C20.1923 8.57878 20.3225 8.895 20.3225 9.26702Z' fill='white'/%3E%3C/svg%3E");
      }
    }
    &Item{
      border: 1px solid rgba(51, 190, 83, 0.3);
      box-shadow: 0px 8px 10px rgba(52, 105, 179, 0.14);
      &::before{
        left: 17px;
        top: 20px;
        background: url("data:image/svg+xml,%3Csvg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.2863 5.57199V7.28627C13.2863 7.52437 13.2029 7.72675 13.0363 7.89341C12.8696 8.06008 12.6672 8.14341 12.4291 8.14341H8.71484V11.8577C8.71484 12.0958 8.63151 12.2982 8.46484 12.4648C8.29818 12.6315 8.0958 12.7148 7.8577 12.7148H6.14342C5.90532 12.7148 5.70294 12.6315 5.53627 12.4648C5.36961 12.2982 5.28627 12.0958 5.28627 11.8577V8.14341H1.57199C1.33389 8.14341 1.13151 8.06008 0.964844 7.89341C0.798177 7.72675 0.714844 7.52437 0.714844 7.28627V5.57199C0.714844 5.33389 0.798177 5.13151 0.964844 4.96484C1.13151 4.79818 1.33389 4.71484 1.57199 4.71484H5.28627V1.00056C5.28627 0.762463 5.36961 0.560081 5.53627 0.393414C5.70294 0.226748 5.90532 0.143414 6.14342 0.143414H7.8577C8.0958 0.143414 8.29818 0.226748 8.46484 0.393414C8.63151 0.560081 8.71484 0.762463 8.71484 1.00056V4.71484H12.4291C12.6672 4.71484 12.8696 4.79818 13.0363 4.96484C13.2029 5.13151 13.2863 5.33389 13.2863 5.57199Z' fill='%2333BE53'/%3E%3C/svg%3E") no-repeat center center / contain;
      }
    }
  }
  .minuses{
    & strong{
      color: #FF5353;
      font-weight: 700;
    }
    &Title{
      background: rgba(255, 83, 83, 0.07);
      border: 1px solid #F9C0C3;
      box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.08);
      &:before{
        background-color: #FF5353;
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='7' viewBox='0 0 21 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.3225 2.2673V4.94587C20.3225 5.31789 20.1923 5.63411 19.9319 5.89453C19.6715 6.15495 19.3553 6.28516 18.9833 6.28516H2.01897C1.64695 6.28516 1.33073 6.15495 1.07031 5.89453C0.809896 5.63411 0.679688 5.31789 0.679688 4.94587V2.2673C0.679688 1.89528 0.809896 1.57906 1.07031 1.31864C1.33073 1.05822 1.64695 0.928013 2.01897 0.928013H18.9833C19.3553 0.928013 19.6715 1.05822 19.9319 1.31864C20.1923 1.57906 20.3225 1.89528 20.3225 2.2673Z' fill='white'/%3E%3C/svg%3E");
        background-size: contain;
      }
    }
    &Item{
    &::before{
      left: 16px;
      top: 19px;
      background: url("data:image/svg+xml,%3Csvg width='14' height='5' viewBox='0 0 14 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.2863 1.57115V3.28544C13.2863 3.52353 13.2029 3.72591 13.0363 3.89258C12.8696 4.05924 12.6672 4.14258 12.4291 4.14258H1.57199C1.33389 4.14258 1.13151 4.05924 0.964844 3.89258C0.798177 3.72591 0.714844 3.52353 0.714844 3.28544V1.57115C0.714844 1.33305 0.798177 1.13067 0.964844 0.964007C1.13151 0.79734 1.33389 0.714006 1.57199 0.714006H12.4291C12.6672 0.714006 12.8696 0.79734 13.0363 0.964007C13.2029 1.13067 13.2863 1.33305 13.2863 1.57115Z' fill='%23FF5353'/%3E%3C/svg%3E") no-repeat center center / contain;
    }
    }
  }
}