.faq {
  grid-area: faq;
  &Item {
    background: var(--white);
    box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.15);
    border-radius: var(--main-border-radius);
    margin-bottom: 4px;
    position: relative;
    transition: all 0.3s;
    overflow: hidden;
    &Question {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 20px;
      font-size: 16px;
      font-weight: 400;
      padding: 12px;
      margin: 0;
      cursor: pointer;
      background-color: #252734;
      color: var(--white);
      text-transform: none;
      text-align: start;
      &::after {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        background: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8886 1.36794C11.9678 1.44705 12.0073 1.53803 12.0073 1.64088C12.0073 1.74372 11.9678 1.8347 11.8886 1.91382L6.35863 7.44383C6.27951 7.52294 6.18853 7.5625 6.08568 7.5625C5.98284 7.5625 5.89186 7.52294 5.81274 7.44383L0.282732 1.91382C0.203619 1.8347 0.164062 1.74372 0.164062 1.64088C0.164062 1.53803 0.203619 1.44705 0.282732 1.36794L0.876081 0.774589C0.955194 0.695475 1.04617 0.655919 1.14902 0.655919C1.25187 0.655919 1.34285 0.695475 1.42196 0.774589L6.08568 5.43831L10.7494 0.774589C10.8285 0.695475 10.9195 0.655919 11.0223 0.655919C11.1252 0.655919 11.2162 0.695475 11.2953 0.774589L11.8886 1.36794Z' fill='%23FBFBFB'/%3E%3C/svg%3E") no-repeat center center / contain;
        transition: all 0.3s;
        flex-shrink: 0;
        transform: rotate(-180deg);
        .open &{
          transform: rotate(0deg);
        }
      }
    }
    &Answer {
      max-height: 0;
      overflow: hidden;
      padding: 0 21px;
      transition: all 0.3s;
      p{
        margin: 21px 0;
      }
      &.open{
        max-height: 1000px;
      }
    }
  }
}