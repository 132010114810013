.textCard{
  &.left{
    .textCardWrapper{
      flex-direction: row-reverse;
    }
  }

  &Wrapper{
    display: flex;
    width: 100%;
    background: var(--light-white);
    box-shadow: 0px 8px 15px rgba(52, 105, 179, 0.1);
    padding: 4px;
    border-radius: var(--main-border-radius);
    margin-bottom: 16px;
    @include breakpoint(medium down) {
        display: block;
    }
  }
  &Title{
    border: 8px solid var(--light-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 180px;
    width: 180px;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    text-transform: none;
    padding: 0 20px;
    @include breakpoint(medium down) {
      min-width: 100%;
      width: 100%;
      margin-bottom: 4px;
      min-height: 100px;
    }
  }
  &Text{
    border: 8px solid var(--light-blue);
    padding: 3px 20px;
    flex: 1;
  }
}